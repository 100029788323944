import axios from "axios";
import $ from "jquery";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// Three.js
import * as THREE from "../threejs/three.module.js";

import { OrbitControls } from "../threejs/OrbitControls.js";
import { GLTFLoader } from "../threejs/GLTFLoader.js";
import { RGBELoader } from "../threejs/RGBELoader.js";
import { RoughnessMipmapper } from "../threejs/RoughnessMipmapper.js";

// For client identification
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { v4 as uuidv4 } from "uuid";

// jQuery in window
window.jQuery = $;
window.$ = $;

// loads the Icon plugin
UIkit.use(Icons);

// .env
const SERVER_API = process.env.SERVER_API;
const SERVER_KEY = process.env.SERVER_KEY;

// Globals
let visitorId = "";
let keys = {};
let server_keys = {};

// Initialize a fingerprint agent at application startup.
const fpPromise = FingerprintJS.load();

// Three.js
let camera, scene, renderer;

/**
 * Get the example key. In the real world, you want to generate these randomly.
 */
async function setUserKey() {
  if (!window.sodium) window.sodium = await SodiumPlus.auto();

  const selfKeyPair = await window.sodium.crypto_box_keypair(),
    selfPublicKey = await window.sodium.crypto_box_publickey(selfKeyPair),
    selfSecretKey = await window.sodium.crypto_box_secretkey(selfKeyPair),
    selfPublicKeyASCII = await window.sodium.sodium_bin2hex(
      selfPublicKey.getBuffer()
    );

  return {
    selfKeyPair: selfKeyPair,
    selfPublicKey: selfPublicKey,
    selfSecretKey: selfSecretKey,
    selfPublicKeyASCII: selfPublicKeyASCII,
  };
}

async function setServerKey() {
  if (!window.sodium) window.sodium = await SodiumPlus.auto();
  return CryptographyKey.from(SERVER_KEY, "hex");
}

async function encryptString(message, key) {
  if (!window.sodium) window.sodium = await SodiumPlus.auto();

  let nonce = await sodium.randombytes_buf(24);
  let encrypted = await sodium.crypto_secretbox(message, nonce, key);
  return nonce.toString("hex") + encrypted.toString("hex");
}

async function encryptMessage(text, keys) {
  let crypted;

  try {
    crypted = await sodium.crypto_box_seal(text, keys.selfPublicKey);
  } catch (error) {
    console.warn(error);
  }

  return crypted;
}

async function decryptMessage(text, keys) {
  let decrypted;
  let text_hex = await window.sodium.sodium_hex2bin(text);

  try {
    decrypted = await sodium.crypto_box_seal_open(
      text_hex,
      keys.selfPublicKey,
      keys.selfSecretKey
    );
  } catch (error) {
    console.warn(error);
  }

  return decrypted.toString("utf-8");
}

async function decryptImage(text, keys) {
  let decrypted;
  let text_hex = await window.sodium.sodium_hex2bin(text);

  try {
    decrypted = await sodium.crypto_box_seal_open(
      text_hex,
      keys.selfPublicKey,
      keys.selfSecretKey
    );
  } catch (error) {
    console.warn(error);
  }

  return decrypted.toString("utf-8");
}

async function decryptModel(model, keys) {
  let decrypted;
  let model_hex = await window.sodium.sodium_hex2bin(model);
  try {
    decrypted = await sodium.crypto_box_seal_open(
      model_hex,
      keys.selfPublicKey,
      keys.selfSecretKey
    );
  } catch (error) {
    console.warn(error);
  }

  return decrypted.buffer;
}

async function sendMessage() {
  let message = jQuery("#user-input").val();

  axios
    .post(SERVER_API + "/encrypt_message", {
      message: message,
      key: keys.selfPublicKeyASCII,
    })
    .then(function (response) {
      // console.log(response);
      return decryptMessage(response.data, keys);
    })
    .then(function (decryptedMessage) {
      // console.log("decryptedMessage", decryptedMessage);
      jQuery("#output-message").append(
        "<li><pre>" + decryptedMessage + "</pre></li>"
      );
    })
    .catch(function (error) {
      console.error(error);
    });
}

async function fetchImage() {
  let image = jQuery("#input-image").val();

  axios
    .get(SERVER_API + "/image", {
      params: {
        image: image,
        key: keys.selfPublicKeyASCII,
      },
    })
    .then(function (response) {
      // console.log(response);
      return decryptImage(response.data, keys);
    })
    .then(function (decryptedImage) {
      // console.log("decryptedImage", decryptedImage);
      jQuery("#output-image").append(
        "<li><img src='data:image/jpg;base64," + decryptedImage + "'/></li>"
      );
    })
    .catch(function (error) {
      console.error(error);
    });
}

function init() {
  const container = document.createElement("div");
  document.getElementById("threejs-container").appendChild(container);

  camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.25,
    20
  );
  camera.position.set(-1.8, 0.6, 2.7);

  scene = new THREE.Scene();

  new RGBELoader()
    .setPath("./textures/equirectangular/")
    .load("royal_esplanade_1k.hdr", function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;

      scene.background = texture;
      scene.environment = texture;

      render();

      // use of RoughnessMipmapper is optional
      const roughnessMipmapper = new RoughnessMipmapper(renderer);

      const loader = new GLTFLoader();

      setServerKey()
        .then((key) => {
          // Bundle up query and Key
          const query = {
            model: "models/Avocado.glb",
            key: keys.selfPublicKeyASCII,
          };
          let json_query = btoa(JSON.stringify(query)); // base64 string
          json_query = reverseString(json_query); // Reverse string for fun

          return encryptString(json_query, key);
        })
        .then((json_query) => {

          axios
            .get(SERVER_API + "/model", {
              params: {
                model_hash: json_query,
                model_key: visitorId, // Useless, added just for funzies
              },
            })
            .then(function (response) {
              return decryptModel(response.data, keys);
            })
            .then(function (decryptedModel) {
              // console.log("decryptedModel", decryptedModel);

              loader.parse(decryptedModel, "", function (glb) {
                glb.scene.traverse(function (child) {
                  if (child.isMesh) {
                    roughnessMipmapper.generateMipmaps(child.material);
                  }
                });

                scene.add(glb.scene);

                roughnessMipmapper.dispose();

                render();
              });
            })
            .catch(function (error) {
              console.error(error);
            });

        });

    });

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.outputEncoding = THREE.sRGBEncoding;
  container.appendChild(renderer.domElement);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.addEventListener("change", render); // use if there is no animation loop
  controls.minDistance = 2;
  controls.maxDistance = 10;
  controls.target.set(0, 0, -0.2);
  controls.update();

  window.addEventListener("resize", onWindowResize);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);

  render();
}

function render() {
  renderer.render(scene, camera);
}

function reverseString(s) {
  return s.split("").reverse().join("");
}

// Misc protection
// take body to change the content
const body = document.getElementsByTagName("body");
// stop keyboard shortcuts
window.addEventListener("keydown", (event) => {
  if (event.ctrlKey && (event.key === "S" || event.key === "s")) {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }

  if (event.ctrlKey && event.key === "C") {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }
  if (event.ctrlKey && (event.key === "E" || event.key === "e")) {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }
  if (event.ctrlKey && (event.key === "I" || event.key === "i")) {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }
  if (event.ctrlKey && (event.key === "K" || event.key === "k")) {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }
  if (event.ctrlKey && (event.key === "U" || event.key === "u")) {
    event.preventDefault();
    console.log("blocked");

    UIkit.notification({
      message: "Blocked!",
      status: "warning",
      pos: "top-right",
      timeout: 1000,
    });
  }
});

// stop right click
document.addEventListener("contextmenu", function (e) {
  e.preventDefault();
});

(async () => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();

  // This is the visitor identifier:
  visitorId = result.visitorId;

  // This is the crypto keys pair
  keys = await setUserKey();

  jQuery(document).ready(function () {
    jQuery("#send-message").on("click", sendMessage);

    jQuery("#fetch-image").on("click", fetchImage);

    init();
    render();
  });
})();
